import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import Logo from "../../contents/img/logo.png"
import MenuLogo from "../../contents/assets/menu.svg"


export const Headermain = () => {

    const [isActive, setActive] = useState("false");

    const handleToggle = () => {
        setActive(!isActive);
        document.body.classList.toggle("ovhidden");
    };

    document.addEventListener("DOMContentLoaded", function () {
        var dropdowns = document.querySelectorAll('.dropdown');
      
        dropdowns.forEach(function (dropdown) {
          dropdown.addEventListener('click', function () {
            this.classList.toggle('active');
          });
        });
      });
      

    return (
        <><header>
            <div>
                <img src={Logo} className="header_site_logo" />
                <text className="header_site_name">BittuKrishi</text>

            </div>

            <div>
                <ul className="main_menu">

                    <li className="menu_item ">
                        <Link onClick={handleToggle} to="/" className="links">Home</Link>
                    </li>
                    <li className="menu_item ">
                        <Link onClick={handleToggle} to="/download" className="links">Download</Link>
                    </li>
                    <li className="menu_item ">
                        <Link onClick={handleToggle} to="/services" className="links">Services</Link>
                    </li>
                    <li className="menu_item ">
                        <Link onClick={handleToggle} to="/whyismahatvapoorn" className="links">Why is This</Link>
                    </li>
                    <li className="menu_item">
                        <Link onClick={handleToggle} to="/about" className="links">About Us</Link>
                    </li>
                    <li className="menu_item">
                        <Link onClick={handleToggle} to="/contact" className="links">Contact Us</Link>
                    </li>
                    <li className="menu_item">
                        <Link onClick={handleToggle} to="/help" className="links">Help</Link>
                    </li>
                </ul>
            </div>
            
            <div class="dropdown">
                <img src={MenuLogo} class="dropbtn" />
                <div class="dropdown-content">
                <Link onClick={handleToggle} to="/" className="links">Home</Link>
                <Link onClick={handleToggle} to="/download" className="links">Download</Link>
                <Link onClick={handleToggle} to="/services" className="links">Services</Link>
                <Link onClick={handleToggle} to="/whyismahatvapoorn" className="links">Why is This</Link>
                <Link onClick={handleToggle} to="/about" className="links">About Us</Link>
                <Link onClick={handleToggle} to="/privacy-policy" className="links">Privacy Policy</Link>
                <Link onClick={handleToggle} to="/terms-of-service" className="links">Terms of Service</Link>
                <Link onClick={handleToggle} to="/faq" className="links">FAQ</Link>
                <Link onClick={handleToggle} to="/contact" className="links">Contact Us</Link>
                <Link onClick={handleToggle} to="/help" className="links">Help</Link>
                </div>
            </div>
            
            
        </header>
            <hr className="hr" />
        </>
    );
};

